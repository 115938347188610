
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.supplier-appointment {
  .change-supplier {
    height: 24px;
    justify-self: flex-start;
  }

  .border-wrapper {
    border: 1px solid $gray-light-2;
    border-radius: 8px;
    padding: 24px;
  }
}

