
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.gac-icon {
  height: 20px;
  width: 61px;

  .inside {
    fill: var(--gac-icon-fill);
  }
}

