
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.app-table-skeleton {
  display: grid;
  gap: var(--grid-gap);

  .row {
    display: grid;
    grid-template-columns: var(--grid-columns);
    gap: var(--grid-gap);
  }
}

