
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.kilometer-checker {
  p {
    margin: 0;
  }

  .heading {
    font-weight: 500;
  }

  .label {
    font-size: 14px;
    gap: 7px;
  }

  .sparkle {
    color: $orange;
    font-size: 4px;
  }

  .inputs {
    .submit {
      appearance: none;
      background: transparent;
      border: none;
      padding: 0;
      color: $primary;
      width: 24px;
      aspect-ratio: 1;
      cursor: pointer;

      &:disabled,
      &[disabled],
      &.fetching {
        cursor: not-allowed;
        color: $gray-dark;
      }

      &.fetching {
        animation-name: rotation;
        animation-duration: 800ms;
        animation-iteration-count: infinite;

        @keyframes rotation {
          100% { transform: rotate(1turn) }
        }
      }
    }
  }
}

