
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.modal-ticket-cancellation {
  p {
    margin: 0px;
  }

  .section {
    padding: 16px 24px;

    &:not(:first-child) {
      border-top: 1px solid $gray-light-2;
    }
  }

  .small-padding {
    display: inline-block;
    padding-inline-end: 0.5ch;
  }
}

