
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.tickets-reschedule {
  padding-block: 24px;
  align-content: flex-start;

  .divider {
    height: 1px;
    background-color: $gray-light-2;
  }

  .top {
    min-height: 21px;
  }

  .go-back-text {
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 400;
  }

  .go-back-icon {
    font-size: 12px;
  }
}

