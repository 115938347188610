
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.ticket {
  --ticket-grid-gap: 24px;
  --view-inline-padding: 16px;

  padding-block: 24px;
  gap: var(--ticket-grid-gap);
  align-content: flex-start;

  .divider {
    height: 1px;
    background-color: $gray-light-2;
  }

  .top {
    min-height: 21px;
  }

  .go-back-text {
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 400;
  }

  .go-back-icon {
    font-size: 12px;
  }

  .attempts-loader {
    font-size: 6rem;
    opacity: 0.4;
    padding-block-start: 64px;

    i {
      animation-name: spinning;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
      animation-duration: 1s;
      animation-timing-function: ease-out;
    }

    @keyframes spinning {
      0% { transform: rotate(0turn); }
      100% { transform: rotate(1turn); }
    }
  }
}

