
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.supplier-appointment-create {
  p {
    margin: 0;
  }

  .error {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }

  .empty {
    padding: 12px;
    background-color: $gray-light-3;
    border-radius: 8px;

    img {
      min-height: 32px;
    }

    p {
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      color: $gray;
    }
  }

  .suggestion {
    border: 1px solid $orange;
    border-radius: 8px;
    background-color: $orange-light-3;
    padding: 8px;

    .icon {
      width: 32px;
      aspect-ratio: 1;
      color: $orange;
      font-size: 20px;
    }

    .message {
      font-size: 12px;
    }
  }
}

