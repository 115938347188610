
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        

  .font-small {
    font-size: 14px;
  }

  .font-smaller {
    font-size: 12px;
  }
