
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.multiple-select-field {
  &.selected {
    ::v-deep .placeholder {
      color: $gray-dark;
    }
  }

  .selected-items {
    padding: 8px;
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
    border-bottom: 1px solid $gray-light-3;

    .link {
      font-size: 14px;
      font-weight: 400;
    }

    .icon {
      font-size: 12px;
      margin-right: 4px;
      margin-left: 8px;
    }
  }

  .option {
    min-height: 40px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $gray-light-3;
    cursor: pointer;

    .remove-icon-content {
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 50px;
      color: $gray-2;
      transition: all 100ms linear;
    }

    &.active {
      color: $primary;
      background: $gray-light-2;
    }

    &.focus {
      background: $gray-light-2;
      color: $primary-dark;

      .remove-icon-content {
        background: $gray-light-3;
        color: $primary;
      }
    }
  }
}

