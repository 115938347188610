
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.googlemaps-autocomplete {
  .input-wrapper {
    position: relative;
    font-size: 16px;
    display: inline-block;
    width: 100%;

    .input-inner {
      --icon-padding-left-adjustment: calc(
        var(--input-field-inline-padding) +
        var(--input-field-icon-size, 0.875em) +
        var(--input-field-icon-spacing, 8px)
      );
      --icon-padding-right-adjustment: var(--input-field-inline-padding);

      font-family: $primary-font;
      appearance: none;
      background-color: $white;
      background-image: none;
      border-radius: var(--input-field-radius);
      border: 1px solid $gray-3;
      color: $gray;
      display: inline-block;
      font-size: inherit;
      height: var(--input-field-height);
      line-height: var(--input-field-height);
      outline: none;
      padding-block: 1px;
      width: 100%;
      //
      padding-left: var(--icon-padding-left-adjustment);
      padding-right: var(--icon-padding-right-adjustment) ;

      &:hover {
        border-color: $orange;
      }

      &:focus {
        outline: none;
        border-color: $orange;
        border-width: 2px;
      }

      &::placeholder {
        font-weight: 300;
        color: $gray-3;
        opacity: 1;
      }

      &.disabled {
        background-color: $gray-light-2;
        border-color: $gray-light;
        color: $gray-2;
        cursor: not-allowed;
      }

      &.loading {
        background-color: $gray-light-2;
        border-color: $gray-light;
        color: $gray;
        cursor: progress;
      }

      &.error {
        border-color: $red;
      }
    }

    .icon-left {
      position: absolute;
      font-size: var(--input-field-icon-size, 0.875em);
      color: $gray-2;
      top: calc(var(--input-field-height) / 2);
      left: var(--input-field-inline-padding);
      transform: translateY(-50%);
    }

    .clear {
      position: absolute;

      height: 100%;
      width: 40px;
      top: 0;
      right: 0;

      font-size: 18px;
      background: transparent;

      outline: 0;
      border: 0;

      cursor: pointer;
      color: $gray-2;

      &:hover {
        color: $gray-3;
      }
    }
  }

  .initialize-error {
    font-size: 12px;
    margin-top: 4px;

    span {
      margin-right: 4px;
    }
  }
}

