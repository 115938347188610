
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.draft-existence-warning {
  outline: 1ox solid red;

  p {
    margin: 0;
  }

  .title {
    font-size: 14px;
    font-weight: 400;
  }

  .message {
    padding: 16px;
    border: 1px solid $gray-light-2;
  }

  .details {
    font-size: 12px;
    font-weight: 400;
  }

  .button-text {
    font-size: 14px;
    font-weight: 400;
  }

  .alert {
    padding: 8px;
    border-radius: 8px;
    border: 1px solid $orange;
    background-color: $orange-light-3;
    font-size: 12px;
  }

  .icon {
    width: 32px;
    aspect-ratio: 1;
    color: $orange;
    flex-shrink: 0;
    font-size: 20px;
  }
}

