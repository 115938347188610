
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.target-item {
  p {
    margin: 0;
  }

  .field {
    flex-basis: 179px;
  }

  .icon-button {
    appearance: none;
    background-color: transparent;
    border: none;
    width: 24px;
    aspect-ratio: 1;
    font-size: 24px;
    padding: 0;
    margin-top: calc(17px + 8px); //compensação label de select (font + margin)
    position: relative;

    :where(.fas, .fal) {
      position: absolute;
      top: 0;
      left: 0;
    }

    .fas {
      opacity: 0;
      background-color: white;
    }

    &.primary {
      color: $primary;
    }

    &:disabled,
    &[disabled],
    &.disabled {
      color: $gray-3;
    }

    &:not(.disabled, :disabled, [disabled]):hover {
      cursor: pointer;

      .fas {
        opacity: 1;
      }
    }

    &:focus-visible {
      outline: 2px solid $orange;
      outline-offset: 2px;
    }
  }

  .error {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

