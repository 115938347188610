
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.checklist-modal {
  p {
    margin: 0;
  }

  .wrapper {
    padding: 16px 24px;
  }

  .section {
    grid-template-columns: 40px 1fr;
  }

  .icon {
    width: 40px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color:  #fff6d9;
  }

  .heading {
    font-family: $secondary-font;
    font-weight: 500;
    line-height: 24px;
    padding-bottom: 16px;
  }

  .checkbox-wrapper {
    padding-left: 8px;
    padding-top: 2px;
  }

  .checkbox-label {
    font-size: 14px;
    font-weight: 400;
  }

  .descriptions {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      font-size: 14px;
      font-weight: 300;
    }
  }

  .list-decorator {
    width: 16px;
    font-size: 2px;
  }

  .footer {
    border-top: 1px solid $gray-light;
  }
}

