
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        

.logout-button {
  &:hover {
    background-color: $bg-hover;
  }

  &:active {
    background-color: $bg-active;
  }

  .button {
    padding: 0 24px;
    height: $navbar-height;

    &__content {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .avatar {
      width: 40px;
      aspect-ratio: 1;
      border-radius: 50%;
      background-color: $gray-light-2;
      color: $white;
      display: grid;
      place-items: center;
    }

    .name {
      font-weight: 500;
      font-family: $secondary-font;
      color: $text;
    }

    &:hover {
      .name {
        color: $primary;
      }
    }
  }
}

.modal-body {
  padding: 24px;

  .user-info {
    text-align: center;
    margin-top: 16px;
  }

  .action-button {
    margin-top: 16px;

    .icon {
      margin-right: 8px;
    }
  }
}
