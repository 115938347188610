
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.params-view {
  padding-block: 24px;

  .title {
    --tickets-title-vertical-spacing: 16px;

    border-bottom: 1px solid $gray-light-2;
    padding-bottom: var(--tickets-title-vertical-spacing);
    margin-bottom: var(--tickets-title-vertical-spacing);
    font-size: 1.5rem;

    span {
      font-size: 1.875rem;
      font-family: $secondary-font;
      font-weight: 500;
    }
  }

  .empty-list {
    padding: 16px;
    display: grid;
    justify-items: center;
    align-content: center;
    gap: 48px;
    border-top: 1px solid $gray-light-2;
    min-height: calc(100vh - 270px);

    p {
      margin: 0;
      text-align: center;
      color: $gray;
      font-size: 18px;
      max-width: 48ch;
    }
  }
}

