
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.ticket-show-header {
  .review {
  font-family: $secondary-font;
  font-size: 30px;
  font-weight: 500;
  }

  .id {
    font-size: 24px;
  }

  .override-tag-inline {
    padding: 6px 8px;
    border-radius: 8px;
    font-size: 14px;
    font-family: $secondary-font;
  }

  .open-at {
    margin: 0;
    font-size: 14px;
    color: $gray-dark;

    .fa-diamond {
      font-size: 4px;
      color: $gray-3;
    }
  }
}

