
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.header {

  p {
    margin: 0;
  }

  .top {
    h2 {
      font-size: 1.875rem;
      font-weight: bold;
    }

    .due {
      display: inline-block;
      padding: 4px 12px;
      border-radius: 8px;
      background-color: $green;
      //
      font-family: $secondary-font;
      font-weight: 500;
      color: $white;

      &[data-status="expired"] {
        background-color: $gray-2;
      }
    }
  }

  .bottom {
    font-size: .875rem;
  }

  .fa-diamond {
    font-size: 0.25rem;
    line-height: 1;
    color: $gray-3;
  }

  .col-gray {
    color: $gray;
  }
}

