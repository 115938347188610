
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


button {
  appearance: none;
  border-radius: 50%;
  width: var(--app-icon-button-width);
  aspect-ratio: 1;
  border: none;
  background: transparent;
  transition: all 200ms ease;
  color: $gray-dark-2;

  &:not(:disabled) {
    &:hover {
      background-color: $gray-light-3;
      color: $orange;
      cursor: pointer;
    }
  }

  &:disabled {
    color: $gray-2;
    cursor: not-allowed;
  }
}

