
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.person-search {
  p {
    margin: 0;
  }

  .wrapper {
    padding: 16px 24px;
  }

  .footer {
    border-top: 1px solid $gray-light-2;
  }
}

