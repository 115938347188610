
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.app-datepicker {
  $height: 40px;

  .datepickr-wrapper {
    position: relative;
    font-size: 16px;
    display: inline-block;
    width: 100%;

    .datepickr-inner {
      font-family: $primary-font;
      appearance: none;
      background-color: $white;
      background-image: none;
      border-radius: 4px;
      border: 1px solid $gray-3;
      color: $gray;
      display: inline-block;
      font-size: inherit;
      height: $height;
      line-height: $height;
      outline: none;
      padding: 1px 13px;
      width: 100%;

      &.icon-right {
        padding-right: 40px;

        &:focus {
          padding-right: 39px;
        }
      }

      &.icon-left {
        padding-left: 40px;

        &:focus {
          padding-left: 39px;
        }
      }

      &:hover {
        border-color: $orange;
      }

      &:focus {
        outline: none;
        border-color: $orange;
        border-width: 2px;
        padding: 0 12px;
      }

      &::placeholder {
        font-weight: 300;
        color: $gray-3;
        opacity: 1;
      }

      &.disabled {
        background-color: $gray-light-2;
        border-color: $gray-light;
        color: $gray-2;
        cursor: not-allowed;
      }

      &.loading {
        background-color: $gray-light-2;
        border-color: $gray-light;
        color: $gray;
        cursor: progress;
      }

      &.error {
        border-color: $error;
      }
    }

    .icon {
      position: absolute;
      font-size: 16px;
      top: calc(#{$height + 2px} / 2);
      transform: translateY(-50%);
      color: $gray-dark-2;
      right: 12px;
    }

    .clear-button {
      position: absolute;
      appearance: none;
      border: none;
      background: transparent;
      cursor: pointer;
      width: 24px;
      height: 38px;
      right: 36px;
      top: calc(50% - 19px);
      font-size: 16px;
      padding: 0;
      text-align: center;
      transition: transform .2s ease;
      color: $gray-dark;

      .remove-icon-content {
        width: 24px;
        height: 24px;
        line-height: 26px;
        border-radius: 50px;
        transition: all 100ms linear;
      }

      &.open {
        right: 35px;
        height: 36px;
        top: calc(50% - 18px);
      }

      &:hover {
        color: $primary;

        .remove-icon-content {
          background: $gray-light-2;
        }
      }
    }
  }
}

