
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.requester-new{
  .footer {
    border-top: 1px solid $gray-light;
  }
}

.padding-regular {
  padding: 16px
}

.checkbox{
  padding-top: 16px;
  border-top: 1px solid $gray-light-2;

  &-text {
    font-family: $secondary-font;
    font-weight: 400;
    padding-inline: 16px;
  }
}

.go-back {
  color: $gray-dark !important;

  &:hover {
    color: $orange !important;

    &:active {
      color: $orange-dark !important;
    }
  }
}

