
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.modal-sinister-attacher {
  p {
    margin: 0;
  }

  .wrapper {
    padding: 16px 24px;

    &:not(:first-child) {
      border-top: 1px solid $gray-light-2;
    }
  }

  .thumb {
    padding: 16px;
    background-image: var(--background-image);
    background-color: $yellow-light-2;
    border-radius: 8px;
  }

  .gray-box {
    padding: 8px;
    border-radius: 8px;
    background-color: $gray-light-4;
  }

  .third-party {
  font-family: $secondary-font;
  font-weight: 500;
  }

  .mandatory {
    font-size: 4px;
    color: $orange;
  }

  .description {
    .title {
      font-family: $secondary-font;
      font-size: 14px;
      font-weight: 500;
    }

    .details {
      font-size: 12px;
      font-weight: 400;
    }
  }

  .reminder {
    font-size: 12px;
    font-weight: 400;
  }
}

