
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.ticketHeader {
  font-family: $secondary-font;

  .main {
    .icon {
      object-fit: none;
      object-position: left;
      height: 32px;
    }

    .title {
      font-size: 1.875rem;
      font-weight: 500;
      gap: 0.3ch;
    }
  }

  .detail-up {
    min-height: 22px;
  }

  .divider {
    min-width: 1px;
    min-height: 1px;
    background-color: $gray-light;
    border-radius: 5px;
  }

  .fipe-id {
    color: $gray-2;
    font-size: 0.9em;
  }

  .font-size-small {
    font-size: 0.75rem;
  }

  .fa-diamond {
    font-size: 4px;
    color: $gray-3;
  }

  .bold {
    font-weight: 500;
  }

  .italic {
    font-style: italic;
    font-family: $primary-font;
  }

  .font-large {
    font-size: 1.5rem;
  }

  .append {
    display: inline-block;
    overflow: 0;
  }

  .double-icon {
    position: relative;
    z-index: 1;
    width: 20px;
    aspect-ratio: 1;

    .golden-star {
      font-size: 12px;
      background: linear-gradient(23.13deg, #E0A21A 22.81%, #FFD737 39.85%, #FFFF90 58.12%, #FBDE74 88.36%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
    }

    .golden-star-stroke {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
      //
      background: linear-gradient(186.12deg, #F1D336 35.35%, #B57A21 95.16%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-background-clip: text;
    }
  }

  .manufacturer-icon {
    min-height: 24px;
    align-self: flex-start;
  }

  .color-yellow-dark {
    color: $yellow-dark;
  }
}

