
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.modal-requester-notifications-create {
  --supplier-notifications-border: 1px solid #{$gray-light-2};

  p {
    margin: 0px;
  }

  .wrapper {
    padding: 16px 24px;
  }

  .select-channels {
    padding-block-start: 8px;
  }

  .footer {
    border-top: var(--supplier-notifications-border)
  }

  .requester-indicator {
    color: $gray;
    font-size: 14px;
    margin-bottom: 8px;

    .icon {
      font-size: 12px;
      width: 16px;
      aspect-ratio: 1;
    }
  }
}

