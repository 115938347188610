
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.ticket-starter {
  --ticket-starter-padding: 16px;
  --ticket-starter-gap: 16px;
  --ticket-starter-gap-small: 8px;

  padding: var(--ticket-starter-padding);
  border: 1px solid $gray-light-2;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  gap: var(--ticket-starter-gap);

  p {
    margin: 0;
  }

  .heading {
    font-weight: 500;
  }

  .sparkle {
    color: $orange;
    font-size: 4px;
  }

  .footer {
    --footer-border: 1px solid #{$gray-light-2};

    border-top: var(--footer-border);
    padding-top: var(--ticket-starter-padding);
    gap: var(--ticket-starter-gap);

    & > .bottom-padding {
      padding-bottom: var(--ticket-starter-padding);
    }

    & > .bottom-border {
      border-bottom: var(--footer-border);
    }
  }

  .create{
    gap: var(--ticket-starter-gap-small);
  }

  .channel {
    gap: var(--ticket-starter-gap-small);

    .channel-option {
      display: inline-block;
      font-size: 14px;
      padding-inline: 3px;
      font-family: $secondary-font;
      font-weight: 400;
    }

    .fa-diamond {
      font-size: 4px;
      line-height: 4px;
      color: $gray-3;
    }
  }
}

