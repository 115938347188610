
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.vehicle-data-box {
  .footer > span:not(.tag-inline) {
    font-style: italic;
    font-size: 0.875rem;
    color: $gray;
  }

  .decoration {
    font-size: 0.25rem;
    line-height: 1;
    color: $gray-3;
  }

  .tag-inline {
    font-family: $secondary-font;
    font-size: 12px;
    font-weight: 500;
  }

  &.golden-box {
    padding: 1px;
    background: linear-gradient(180deg, #E4A81D 0%, #FED436 18.23%, #FFFD8C 50.52%, #E2B525 76.04%, #EEC530 100%);
    border-radius: 8px;

    .transparent-border {
      background: linear-gradient(180deg, #F7C75A 0%, #FFE370 1.94%, #FFFD9E 6.55%, #FFFFFF 20.38%);
      border: none;
    }
  }

  .double-icon {
    position: relative;
    z-index: 1;
    width: 20px;
    aspect-ratio: 1;

    .golden-star {
      font-size: 12px;
      background: linear-gradient(23.13deg, #E0A21A 22.81%, #FFD737 39.85%, #FFFF90 58.12%, #FBDE74 88.36%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
    }

    .golden-star-stroke {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
      //
      background: linear-gradient(186.12deg, #F1D336 35.35%, #B57A21 95.16%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-background-clip: text;
    }
  }
}

