
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.tickets-end {
  margin-block: 24px;

  .container {
    width: min(565px, 100%);
    gap: 48px;
  }

  .box {
    padding: 16px;
    border: 1px solid $gray-light;
    border-radius: 4px;
    gap: 32px;
  }

  .title {
    font-family: $secondary-font;
    font-size: 38px;
    font-weight: 500;
  }

  .category {
    font-family: $secondary-font;
    font-size: 18px;
    font-weight: 500;
  }

  .icon {
    color: $purple;
    width: 20px;
    aspect-ratio: 1;
  }

  .big-icon {
    font-size: 38px;
  }

  .tag {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #e7e7e7;
    font-size: 16px;
  }

  .strong {
    font-family: $secondary-font;
    font-weight: 400;
  }

  $icon-colors: (
    'green': $green,
    'yellow': $yellow,
  );

  @each $name, $color in $icon-colors {
    .text-#{$name} {
      color: $color;
    }
  }
}

