
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.customer-popover {
  width: 328px;

  .cell {
    font-size: 0.875rem;
    color: $gray-dark;
    font-family: $secondary-font;
    padding: 16px;

    &:not(:last-child) {
      border-bottom: 1px solid $gray-light-2;
    }

    &.with-icon {
      grid-template-columns: 24px 1fr 16px;
    }

    .icon {
      font-size: 1rem;
      color: $gray-2;
    }

    .unavailable {
      font-style: italic;
      color: $gray;
      font-family: $primary-font;
    }

    button {
      appearance: none;
      background-color: transparent;
      border: none;
      color: $orange;
      position: relative;

      &:hover {
        cursor: pointer;
        color: $orange-dark;
      }

      i {
        --icon-transform-center: translate(-50%, -50%);

        position: absolute;
        top: 50%;
        left: 50%;
        transform: var(--icon-transform-center);
      }

      .fa-check-circle {
        opacity: 0;
      }

      &.animate {
        i {
          animation-direction: alternate;
          animation-fill-mode: forwards;
          animation-duration: 0.6s;
          animation-iteration-count: 2;
          animation-timing-function: linear;
        }

        .fa-copy {
          animation-name: rotate-from-0;
        }

        .fa-check-circle {
          animation-name: rotate-from-180;
        }
      }


      @keyframes rotate-from-0 {
        0% {
          transform: var(--icon-transform-center) rotateY(0);
          opacity:1;
        }

        24% { opacity:1; }
        25% { opacity:0; }

        50%, 100% {
          transform: var(--icon-transform-center) rotateY(0.5turn);
          opacity:0;
        }
      }

      @keyframes rotate-from-180 {
        0% {
          transform: var(--icon-transform-center) rotateY(-0.5turn);
          opacity:0;
        }

        24% { opacity:0; }
        25% { opacity:1; }

        50%, 100% {
          transform: var(--icon-transform-center) rotateY(0);
          opacity:1;
        }
      }
    }
  }
}

