
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.driver-header {
  grid-template-columns: var(--driver-template-columns);
  gap: var(--driver-gap);
  //
  border-radius: 8px 8px 0 0;
  background-color: $gray-light-3;
  font-family: $secondary-font;

  p {
    margin: 0;
  }

  .cell {
    padding: 8px;
    font-size: 0.875rem;
  }

  .decoration {
    font-size: 0.25rem;
    line-height: 1;
    color: $gray-3;
  }
}

