
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.modal-booking-confirmation {
  p {
    margin: 0px;
  }

  .section {
    padding: 16px 24px;

    &:not(:first-child) {
      border-top: 1px solid $gray-light-2;
    }
  }

  .small-padding {
    display: inline-block;
    padding-inline-end: 0.5ch;
  }

  .select-channels {
    padding-block-start: 8px;
  }

  .field-header {
    font-size: 14px;
    font-family: $secondary-font;
    font-weight: 400;
  }

  .field-icon {
    color: $gray;
    width: 16px;
    aspect-ratio: 1;
  }
}

