
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.tickets-draft-profider {
  grid-template-columns: 1fr 330px;
  gap: 56px;

  .main {
    align-content: flex-start;
  }

  .error {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }

  .aside {
    align-content: flex-start;
  }

  .divider {
    min-width: 1px;
    min-height: 1px;
    background-color: $gray-light-2;
  }

  .empty {
    padding: 12px;
    background-color: $gray-light-3;
    border-radius: 8px;

    img {
      min-height: 32px;
    }

    p {
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      color: $gray;
    }
  }

  p {
    margin: 0;
  }
}

