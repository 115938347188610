
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.tag-inline {
  font-size: 12px;
}

.color-gray {
  color: $gray-dark;
}

.identifier-icon {
  width: 20px;
  aspect-ratio: 1;
}

