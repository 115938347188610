
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.side-buttons {
  .font-small {
    font-size: 14px;
  }

  .button-text {
    width: 100%;
    text-align: start;
  }

  .color-black {
    color: $gray;

      &.disabled {
        color: $gray-light;
      }

      &:not(.disabled):hover {
        color: $orange;
      }
  }
}

