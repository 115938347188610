
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.logout {
  height: 100vh;

  .user {
    margin-bottom: 24px;

    .text {
      display: block;
    }
  }
}

