
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.login {
  width: 100%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &.view {
    background-color: $gray-light-3;
    max-width: unset;
  }

  .card {
    padding: 32px;
    background: $white;
    width: 400px;
    border-radius: 16px;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
    position: relative;

    display: inline-flex;
    flex-direction: column;
    align-items: center;

    .vertical-absolute {
      position: absolute;
      transform: translateY(-100%);
      top: 0px;

      .logo-container {
        position: relative;
        padding-bottom: 12px;

        .stamp {
          position: absolute;
          top: 4px;
          left: 80px;
        }

        .logo {
          width: 112px;
        }
      }

      .welcome {
        position: relative;
        font-family: $secondary-font;
        text-align: center;
        width: 100vw;
        padding-bottom: 30px;

        &__title {
          font-weight: 500;
          font-size: 38px;
          color: $gray-dark;
          margin-bottom: 8px;
        }

        &__description {
          margin-block: 0;
        }
      }

      .arrow-up {
        $border-length: 19px;
        top: 0;
        width: 0;
        height: 0;
        border-left: $border-length solid transparent;
        border-right: $border-length solid transparent;
        border-bottom: $border-length solid $white;
      }
    }

    .form {
      width: 100%;

      .fields {
        margin-bottom: 40px;

        &.error {
          margin-bottom: 20px;
        }

        .username {
          margin-bottom: 16px;
        }

        .error-message {
          font-size: 14px;
          color: $error;
          margin-top: 20px;
          font-weight: normal;
          text-align: center;
          background: #ffefef;
          padding: 8px;
          border-radius: 4px;
        }
      }

      .icon {
        margin-right: 8px;
      }
    }
  }
}

