
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.requester-selector {
  --requester-selector-padding: 16px;
  --requester-selector-gap: 16px;
  --requester-selector-gap-small: 8px;

  gap: var(--requester-selector-gap);

  p {
    margin: 0;
  }

  .header {
    font-size: 1rem;

    small {
      font-size: 14px;
    }
  }

  .heading {
    font-weight: 500;
  }

  .check {
    font-size: 12px;
    width: 16px;
    aspect-ratio: 1;
  }

  .sparkle {
    color: $orange;
    font-size: 4px;
  }

  .fold {
    margin-left: auto;
    border: none;
    border-radius: 0;
    background-color: transparent;
    width: 24px;
    aspect-ratio: 1;
    font-size: 1rem;
    cursor: pointer;
  }

  .requester-skeleton {
    border: 1px solid $gray-light-2;
    border-radius: 8px;
    padding: var(--requester-selector-padding);
    gap: var(--requester-selector-gap-small);
  }

  .content {
    gap: var(--requester-selector-gap-small);

    &.transparent {
      opacity: 0.6;
    }
  }

  .empty-list {
    padding: 16px;
    display: grid;
    justify-items: center;
    background-color: $gray-light-4;
    border-radius: 4px;

    p {
      text-align: center;
      font-style: italic;
      color: $gray;
    }
  }

  .add-button {
    margin-block-start: 8px;
  }
}

