
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.kilometrage-feedback {
  padding-block: 4px;
  border-radius: 4px;
  font-size: 12px;
  font-family: $secondary-font;
  min-height: 26px;

  &.big {
    padding-block: 8px;
    font-size: 16px;
  }

  p {
    margin: 0;
  }

  &.invalid {
    color: red;
  }

  &[data-status] {

    padding-inline: 8px;
    font-weight: 500;

    i {
      font-size: 14px;
    }

    &.big {
      padding-inline: 16px;

      i {
        font-size: 16px;
      }
    }
  }

  &[data-status="warning"] {
    background-color: $yellow-light-2;

    i {
      color: $yellow-dark;
    }
  }

  &[data-status="ok"] {
    background-color: $green-light-2;

    i {
      color: $green-dark;
    }
  }

  &[data-status="non-existant"] {
    background-color: $gray-light-2;
  }
}

