
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.service-cart-item {
  padding: 16px;
  grid-template-columns: 20px 1fr 24px;
  column-gap: 10px;

  .fa-wrench {
    color: $gray-2;
  }

  .name {
    font-weight: 400;
    font-size: 16px;
    font-family: $secondary-font;
    align-self: center;
  }

  .details {
    grid-column-start: 2;
  }

  .tag-inline {
    font-size: .7rem;
    padding: 5px 0;
    border-radius: 4px;
  }

  .tag-inline-color {
    font-size: .7rem;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 4px;
    margin: 0 10px;
    color: #fff;
    background-color: #F4781F;
  }

  .part,
  .target {
    gap: 6px;
    font-family: $primary-font;
  }

  .part {
    font-size: 14px;
  }

  .targets {
    font-size: 12px;
    margin-left: 2px;
    padding-left: 8.5px;
    border-left: 1px solid $gray-3;
  }

  .gray {
    color: $gray-3;
  }

  .fa-circle {
    font-size: 6px;
  }

  .fa-diamond {
    font-size: 4px;
  }
}

