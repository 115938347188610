
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.tag-inline {
  border-radius: 4px;
  padding: 2px 4px;
  color: $gray-dark;
  background-color: $gray-light-2;

  &.pill {
    border-radius: 80px;
    padding: 5px 8px;
  }

  &.small-pill {
    border-radius: 80px;
    padding: 2px 6px;
  }

  &.inline-block{
    display: inline-block;
    width: min-content;
  }

  $font-weights: (
    'regular': 400,
    'bold': 500,
    'bolder': 600,
  );

  @each $name, $value in $font-weights {
    &.weight-#{$name} {
      font-weight: $value;
    }
  }

  $tag-colors: (
    /* 'name': (background, color, box-shadow), */
    'gold-gradient-shadow': (linear-gradient(180deg, #FFF4D1 40.1%, #ECD172 100%), null, #AB8C50),
    'silver-gradient-shadow': (linear-gradient(180deg, #F6F6F6 0%, #C4CFD9 100%), null, #1A4F7D),
    'bronze-gradient-shadow': (linear-gradient(180deg, #FFEED9 0%, #FFC690 100%), null, #AE4D34),
    'gray-gradient': (linear-gradient(180deg, #F6F6F6 0%, #D4D4D4 100%), null, null),
    'gray-shadow': (null, null, $gray-light),
    'gray': ($gray-light-2, $gray, null),
    'blue': ($blue-light-2, $blue-dark, null),
    'green': ($green-light-2, $green, null),
    'yellow': ($yellow-light-2, $yellow-dark, null),
    'red': ($red-light-2, $red, null),
    'orange': ($orange-light-0, $orange-dark, null),
    'purple': ($purple-light-2, $purple, null),
    'orange-dark': ($orange, $white, null),
  );

  @each $name, $value in $tag-colors {
    &.color-#{$name},
    &.color-#{$name}-text {
      background: nth($value, 1);

      @if nth($value,3) {
        box-shadow: 0 1px nth($value,3);
      }
    }

    &.color-#{$name}-text {
      color: nth($value, 2)
    }
  }
}

