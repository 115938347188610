
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.messages-history-header {
  grid-template-columns: var(--messages-history-template-columns);
  gap: var(--messages-history-gap);
  font-size: 14px;
  min-height: 24px;

  .fused-columns {
    grid-column: 1 / -1;
  }

  .header-button {
    font-size: 14px;
  }

  .font-secondary {
    font-family: $secondary-font;
    font-weight: 400;
  }
}

