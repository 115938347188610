
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.default-layout {
  --view-height: calc(100vh - var(--navbar-height, 55px));
  --view-inline-padding: 10px;

  .default-container {
    height: var(--view-height);
    width: 100%;
  }

  .default-route {
    overflow-y: auto;
    width: 100%;
  }

  .view {
    width: min(1200px, 100%);
    padding-inline: var(--view-inline-padding);
    margin-inline: auto;
  }
}

