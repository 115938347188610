
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.service-cart {
  --service-cart-border: 1px solid #{$gray-light-2};

  p {
    margin: 0;
  }

  .header {
    font-size: 16px;

    .skeleton-little {
      margin-top: 2px;
    }

    .amount {
      gap: 5px;

      .fa-cart-shopping {
        font-size: 12px;
      }

      p {
        font-size: 14px;
      }
    }

    .expand {
      appearance: none;
      background: transparent;
      border: none;
      padding: 0;
      width: 24px;
      aspect-ratio: 1;
      transition: transform 0.2s ease;

      &:hover {
        cursor: pointer;
      }

      &.open {
        transform: rotate(0.5turn);
      }
    }
  }

  .summary {
    h2 {
      font-size: 16px;
      font-family: $secondary-font;
      font-weight: 500;
    }
  }

  .skeleton,
  .list,
  .empty {
    margin-top: 8px;
  }

  .skeleton:deep(.loading-line) {
    padding: 16px;
  }

  .list {
    border-radius: 8px;
    border: var(--service-cart-border);
  }

  .issue {
    &:not(:last-child) {
      border-bottom: var(--service-cart-border);
    }
  }

  .empty {
    padding: 24px;
    border-radius: 8px;
    background-color: $gray-light-4;
    font-size: 14px;
  }

  .footer {
    --service-cart-footer-spacing: 24px;

    margin-top: var(--service-cart-footer-spacing);
    padding-top: var(--service-cart-footer-spacing);
    border-top: var(--service-cart-border);
  }

  .transparent {
    opacity: 0.4;
  }

  .color-purple {
    color: $purple;
  }
}

