
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


button {
  appearance: none;
  background-color: transparent;
  border: none;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  text-align: start;
  padding: 0;
  //
  display: inline-flex;
  gap: 9px;
  cursor: pointer;

  i {
    display: none;
    color: $orange;
  }

  &:hover {
    background-color: $gray-light-4;

    i{
      display: inline;
    }

    &:active {
      background-color: $orange-light-3;
    }
  }
}

