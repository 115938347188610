
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.modal-body {
  padding: 16px 24px;
}

#viewport {
  width: 100%;
  height: 100vh;
  position: relative;
}

.layout {
  height:100%;
}

