
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.sidebar {
  --sidebar-width: 76px;

  width: var(--sidebar-width);
  min-width: var(--sidebar-width);
  white-space: nowrap;
  background-color: $gray-light-4;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid $gray-light;
  padding-block: 20px;
  transition: width .1s ease-in-out, min-width .1s ease-in-out;

  &.expanded {
    --sidebar-width: 236px;
  }

  .control,
  .separator-icon {
    height: 40px;
  }

  .control{
    cursor: pointer;
    color: $gray-2;

    &:hover {
      color: $orange;

      .gac {
        ::v-deep .inside {
          fill: $orange;
        }
      }
    }

    .collapse-icon {
      margin-inline: 28px 8px;
    }

    .gac {
      width: 38px;

      ::v-deep .inside {
        fill: $gray-3;
      }
    }
  }

  .separator-icon {
    .icon-container {
      width: 24px;
      aspect-ratio: 1;
      margin-inline: 28px 8px;

      .icon {
        font-size: 12px;
        color: $gray-light;
      }
    }
  }

  .sections {
    height: 100%;

    .separator {
      font-weight: 700;
      font-size: 10px;
      text-transform: uppercase;
      color: $gray-2;
      margin-left: 28px;
      height: 40px;
    }
  }
}

