
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.driver-row {
  grid-template-columns: var(--driver-template-columns);
  gap: var(--driver-gap);
  //
  border-top: 1px solid $gray-light-2;
  font-family: $secondary-font;
  font-weight: 400;

  &:last-of-type {
    border-bottom: 1px solid $gray-light-2;
  }

  p {
    margin: 0;
  }

  .cell {
    padding-block: 12px;
    padding-inline: 8px;
    font-size: 14px;
  }

  .primary-font {
    font-family: $primary-font;
  }

  .monospace-font {
    font-family: $primary-monospace-font;
  }
}

