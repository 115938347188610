
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.input-field {
  .label-content {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
    color: $gray-dark;
    transition: color 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);

    > * + * {
      padding-left: 4px;
    }

    &.focus {
      color: $orange;
    }

    &.error {
      color: $red;
    }

    .mandatory-icon {
      font-size: 4px;
      color: $orange;
    }

    .info-icon {
      font-size: 14px;
      cursor: pointer;
    }
  }

  .input-wrapper {
    --icons-gap: 16px;

    position: relative;
    font-size: 16px;
    display: inline-block;
    width: 100%;

    .input-inner {
      --icon-padding-left-adjustment:var(--input-field-inline-padding);
      --icon-padding-right-adjustment:var(--input-field-inline-padding);

      font-family: $primary-font;
      appearance: none;
      background-color: $white;
      background-image: none;
      border-radius: var(--input-field-radius);
      border: 1px solid $gray-3;
      color: $gray;
      display: inline-block;
      font-size: inherit;
      height: var(--input-field-height);
      line-height: var(--input-field-height);
      outline: none;
      padding-block: 1px;
      width: 100%;
      //
      padding-left: var(--icon-padding-left-adjustment);
      padding-right: calc(
        var(--icon-padding-right-adjustment) +
        var(--second-icon-padding-right-adjustment, 0px)
      );

      &.icon-right {
        --icon-padding-right-adjustment: calc(
          var(--input-field-inline-padding) +
          var(--input-field-icon-size, 0.875em) +
          var(--input-field-icon-spacing, 8px)
        );
      }

      &.clear-icon {
        --second-icon-padding-right-adjustment: calc(
          var(--input-field-icon-size, 0.875em)
        );

        cursor: pointer;

        &.icon-right {
          --second-icon-padding-right-adjustment: calc(
            var(--input-field-icon-size, 0.875em) +
            calc(var(--icons-gap) * 2)
          );
        }
      }

      &.icon-left {
        --icon-padding-left-adjustment: calc(
          var(--input-field-inline-padding) +
          var(--input-field-icon-size, 0.875em) +
          var(--input-field-icon-spacing, 8px)
        );
      }

      &:hover {
        border-color: $orange;
      }

      &:focus {
        outline: none;
        border-color: $orange;
        border-width: 2px;
        padding-left: calc(var(--icon-padding-left-adjustment) - 1px);
        padding-right: calc(var(--icon-padding-right-adjustment) - 1px);
      }

      &::placeholder {
        font-weight: 300;
        color: $gray-3;
        opacity: 1;
      }

      &.disabled {
        background-color: $gray-light-2;
        border-color: $gray-light;
        color: $gray-2;
        cursor: not-allowed;
      }

      &.loading {
        background-color: $gray-light-2;
        border-color: $gray-light;
        color: $gray;
        cursor: progress;
      }

      &.error {
        border-color: $red;
      }
    }

    .suffix {
      position: absolute;
      font-size: 14px;
      font-weight: normal;
      color: $gray;
      top: 12px;
      right: 0;
      display: block;
      padding-inline: var(--input-field-icon-spacing, 8px) var(--input-field-inline-padding);
      user-select: none;
      pointer-events: none;
    }

    .icons {
      position: absolute;
      font-size: var(--input-field-icon-size, 0.875em);
      top: calc(var(--input-field-height) / 2);
      transform: translateY(-50%);
      color: $gray-dark-2;
      gap: var(--icons-gap);

      &.right {
        right: var(--input-field-inline-padding);
      }

      &.left {
        left: var(--input-field-inline-padding);
      }

      .clickable {
        cursor: pointer;
      }

      .divider {
        min-width: 1px;
        min-height: 1px;
        background-color: $gray-light;
      }
    }
  }

  .error-message {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

