
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.tabs-selector {
  border-bottom: 1px solid $gray-light;
  padding-block: 21px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    height: 4px;
    background-color: $primary;
    bottom: 0;
    left: var(--tab-left);
    width: var(--tab-width);
    transition: all 0.2s ease-out;
  }

  .tab {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: 16px;

    &.selected {
      color: $primary;
    }

    &:not(.selected) {
      cursor: pointer;
    }
  }
}

