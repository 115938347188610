
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.modal-tickets-table-vehicle {
  grid-template-columns: 24px 1fr;
  column-gap: 8px;
  row-gap: 2px;

  .second-column {
    grid-column-start: 2;
  }

  h2 {
    font-size: 24px;
    font-family: $secondary-font;
  }

  p {
    margin: 0;
    font-family: $secondary-font;
    color: $gray;
    font-weight: 500;
    text-transform: uppercase;
  }
}

