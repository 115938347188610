
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.sidebar-icon {
  width: 24px;
  aspect-ratio: 1;
  font-size: 16px;
}

