
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.messages-history-item {
  --message-padding-block: 16px;
  --message-lines-gap: 6px;

  grid-template-columns: var(--messages-history-template-columns);
  gap: var(--messages-history-gap);

  p {
    margin: 0;
  }

  .padded-block {
    padding-block: var(--message-padding-block);
  }

  .createdAt {
    font-size: 14px;

    p:first-child {
      font-weight: 400;
    }
  }

  .body {
    font-size: 14px;
  }

  .content {
    border-radius: 8px;
    padding: 16px;
    background-color: $gray-light-3;

    &.bg-purple-light {
      background-color: $purple-light-2;
    }
  }

  .font-secondary {
    font-family: $secondary-font;
    font-weight: 400;
  }

  .color-gray {
    color: $gray-2;
  }

  .color-gray-light {
    color: $gray-3;
  }

  .color-purple {
    color: $purple-2;
  }

  .decoration {
    font-size: 0.25rem;
    line-height: 1;
    color: $gray-3;
  }

  .client-interaction {
    font-style: italic;
  }

  .truncated-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .button-content {
    font-size: 12px;
    font-weight: 400;
    color: $gray;
  }
}

