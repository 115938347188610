
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.paginator {
  .loading {
    cursor: progress;
    width: 400px;
    margin: 0 auto;
  }

  .content {
    &.disabled {
      cursor: not-allowed;
      color: $gray-light;

      .button {
        cursor: not-allowed;

        &.current {
          background-color: $gray-light-2
        }

        &:not(:disabled) {
          cursor: not-allowed;
        }

        &.ellipsis {
          color: $gray-light;
        }
      }
    }

    .button {
      $paginator-gutter: 12px;

      border: none;
      font-family: $secondary-font;
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      color: $gray-dark;
      background-color: transparent;
      padding: 4px;
      margin: 0 $paginator-gutter;
      min-width: 25px;
      position: relative;

      &:not(:disabled) {
        cursor: pointer;

        .navigation {
          color: $primary;
        }
      }

      &:disabled:not(.ellipsis) {
        color: $gray-2;
      }

      &:first-child {
        margin: 0 $paginator-gutter 0 0;
      }

      &:last-child {
        margin: 0 0 0 $paginator-gutter;
      }

      &.current {
        border-radius: 4px;
        background-color: #FFF0E2;
        color: $primary;
      }

      &:focus {
        color: $primary-light;
      }

      .navigation {
        .next,
        .previous {
          font-weight: 400;
        }

        .next {
          margin-right: 4px;
        }

        .previous {
          margin-left: 4px;
        }

        .icon {
          font-size: 12px;
        }
      }
    }

    .page-input {
      width: 100px;
    }
  }
}

