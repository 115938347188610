
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.app-span {
  &.crop {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}

