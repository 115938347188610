
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.sidebar-item {
  --item--left-border-width: 4px;

  .disabled-item {
    cursor: not-allowed;
    color: $gray-3;

    .text{
      color: $gray-3;
    }
  }

  .icon {
    margin-inline: 24px 8px;
  }

  .text {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
    color: $gray-dark-2;
  }

  .item {
    padding-block: 10px;
    border-left: var(--item--left-border-width) solid $gray-light-2;
    border-radius: 0;
    width: 100%;
    font-size: 16px;
    user-select: none;

    &.link {
      color: $gray;
      position: relative;
      height: 40px;

      &:hover {
        color: $orange;

        & ::v-deep .custom-icon {
          background-color: $orange;
        }

        .text {
          color: $orange;
        }
      }

      &.active {
        color: $orange;
        background-color: $orange-light-2;

        & ::v-deep .custom-icon {
          background-color: $orange;
        }

        .text {
          color: $orange;
        }
      }

      &.active::before {
        content: '';
        height: 100%;
        position: absolute;
        left: calc(0px - var(--item--left-border-width));
        border-right: var(--item--left-border-width) solid $orange;
        border-radius: 0 4px 4px 0;
      }
    }
  }
}

