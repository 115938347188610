
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        
@import '@/assets/styles/tooltip.scss';
@import '@/assets/styles/transitions.scss';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  outline: 0;
  box-sizing: border-box;
}

body {
  color: $text;
  background-color: white;
  margin: 0;
  font-family: $primary-font;
  font-weight: 300;
}

.material-icons-outlined {
  vertical-align: middle;
  line-height: unset;
}

a {
  text-decoration: none;
  color: $orange;
  cursor: pointer;

  &:hover {
    color: $orange-dark;
  }
}

h1 {
  margin: 0;
  font-size: 24px;
  font-weight: normal;
}

h2 {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
}

h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

h1, h2, h3 {
  color: $gray-dark;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex,
.inline-flex {
  &.column-direction {
    flex-direction: column;
  }

  &.vertical-center {
    align-items: center;
  }

  &.vertical-bottom {
    align-items: flex-end;
  }

  &.vertical-baseline {
    align-items: baseline;
  }

  &.vertical-start {
    align-items: start;
  }

  &.center {
    justify-content: center;
    text-align: initial;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.start {
    justify-content: flex-start;
  }

  &.end {
    justify-content: flex-end;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  & > .grow {
    flex-grow: 1;
  }

  & > .self-vertical-center {
    align-self: center;
  }

  & > .self-vertical-start {
    align-self: start;
  }

  & > .no-shrink {
    flex-shrink: 0;
  }
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.grid,
.inline-grid {
  &.center {
    justify-items: center;
  }

  &.start {
    justify-items: flex-start;
  }

  &.vertical-center {
    align-items: center;
  }

  &.vertical-bottom {
    align-items: flex-end;
  }

  &.vertical-baseline {
    align-items: baseline;
  }

  &.vertical-start {
    align-items: start;
  }

  &.space-between {
    justify-content: space-between;
  }

  & > .grid-row {
    grid-column: 1 / -1;
  }

  & > .self-vertical-start {
    align-self: start;
  }

  & > .self-vertical-center {
    align-self: center;
  }

  & > .self-start {
    justify-self: start;
  }

  & > .self-center {
    justify-self: center;
  }
}

.gap {
  &-tiny { gap: 4px; }

  &-small { gap: 8px; }

  &-regular { gap: 16px; }

  &-big { gap: 24px; }
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.global-empty-color {
  color: $gray-dark;
}

.global-empty-text {
  color: $gray-3;
  font-style: italic;
  font-weight: 300;
}

.preserve-whitespace {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

//
// desligando botão de "autocomplete" de inputs `type="email"` ou `type="tel"`, pois eles
// possuem ícone próprio (na UI) e o botão extra do Safari torna o input muito pequeno!
//
// @see https://gist.github.com/jeroenvisser101/9254cf27df85cf1c853aee17e4b49766
input[autocomplete="off"]::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  height: 0;
  width: 0;
  margin: 0;
}
