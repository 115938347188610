
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.contract {
  --main-aside-gap: 20px;
  --view-inline-padding: var(--main-aside-gap);
  --contract-gap: 24px;

  padding-block: 20px;
  align-content: start;

  &,
  .main {
    gap: var(--contract-gap);
    align-content: flex-start;
  }

  .header > * {
    width: min(520px, calc(100% - 20px));
    margin-inline: auto;
  }

  .content {
    grid-template-columns: 1fr 330px;
    gap: 56px;
  }

  .divider {
    min-height: 1px;
    min-width: 1px;
    background-color: $gray-light-2;
  }

  .transparent {
    opacity: 0.4;
  }

  :deep(.col-primary) {
    color: $primary
  }
}

