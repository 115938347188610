
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.modal-requester-notifications {
  --requester-notifications-border: 1px solid #{$gray-light-2};

  p {
    margin: 0px;
  }

  .wrapper {
    padding: 16px 24px;
  }

  .points {
    border-block: var(--requester-notifications-border);
    max-height: min(474px, 55vh);
    overflow-x: scroll;
  }

  .point {
    padding: 16px;

    &:not(:last-child) {

      border-block-end: var(--requester-notifications-border);
    }
  }

  .sentBy {
    font-size: 14px;
  }

  .empty {
    padding: 32px;
    background-color: $gray-light-3;
    color: $gray;
    font-style: italic;
  }
}

