
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.input-field {
  width: 100%;

  .label-content {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
    color: $gray-dark;
    transition: color 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);

    > * + * {
      padding-left: 4px;
    }

    &.focus {
      color: $orange;
    }

    &.error {
      color: $red;
    }

    .mandatory-icon {
      font-size: 4px;
      color: $orange;
    }

    .info-icon {
      font-size: 14px;
      cursor: pointer;
    }
  }

  .error-message {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

