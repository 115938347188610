// ref https://v-tooltip.netlify.app/migration/migration-from-v2.html#css-classes

.v-popper__popper {
  &[data-popper-placement^="bottom"] {
    .v-popper__wrapper {
      padding-top: 5px;

      .v-popper__arrow-container {
        top: 5px;
      }
    }
  }

  &[data-popper-placement^="top"] {
    .v-popper__wrapper {
      padding-bottom: 5px;
    }
  }

  &[data-popper-placement^="left"] {
    .v-popper__wrapper {
      padding-right: 5px;

      .v-popper__arrow-container {
        right: -5px;
      }
    }
  }

  &[data-popper-placement^="right"] {
    .v-popper__wrapper {
      padding-left: 5px;
    }
  }

  &.v-popper--theme-tooltip {
    .v-popper__inner {
      background: $gray-dark-2;
      color: $white;
      font-size: 13px;
      font-weight: 400;
      padding: 4px 12px;
      border-radius: 4px;
    }

    .v-popper__arrow-outer {
      border-color: $gray-dark-2;
    }

    .v-popper__arrow-inner {
      border: 0;
    }
  }

  &.v-popper--theme-dropdown {
    .v-popper__inner {
      background: $white;
      color: $gray-dark-2;
      padding: 0;
      border: 0;
      border-radius: 5px;
      box-shadow: 0px 4px 32px rgba(134, 134, 134, 0.31);
    }

    .v-popper__arrow-outer {
      border-color: $white;
    }

    .v-popper__arrow-inner {
      border: 0;
    }
  }
}
