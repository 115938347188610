
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.navbar {
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  background-color: $white;
  position: relative;
  z-index: 1;
  width: 100%;
  top: 0;
  height: $navbar-height;

  .left-menu {
    position: relative;
    margin-left: 24px;

    .stamp {
      position: absolute;
      top: 2px;
      left: 50px;
    }

    .button {
      .logo {
        width: 77px;
        height: 23px;
      }

      .divider {
        height: 16px;
        width: 1px;
        background-color: $gray-light;
        margin: 0 24px;
      }

      .gac-name {
        min-width: 140px;
        margin-left: 8px;
        font-family: $secondary-font;
        color: $gray-dark;
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
        text-align: left;
      }
    }
  }

  .right-menu {
    border-left: 1px solid $gray-light;
  }
}

