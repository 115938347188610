
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.appointment-summary {
  .header {
    font-size: 14px;
    font-family: $secondary-font;
    font-weight: 400;
  }

  i {
    color: $gray;
  }

  p {
    margin: 0;
  }

  .icon {
    width: 16px;
    aspect-ratio: 1;
  }

  .content {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
  }

  .font-small {
    font-size: 14px;
    font-weight: 400;
  }
}

