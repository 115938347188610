
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.search {
  main {
    padding-top: 144px;
    padding-top: min(calc((var(--view-height) - 200px) / 2), 144px);
  }

  main {
    .form {
      width: min(690px, calc(100% - 20px));
    }

    .logo {
      position: relative;

      .stamp {
        position: absolute;
        top: 4px;
        left: 80px;
      }

      .img {
        width: 141px;
        aspect-ratio: 1.66;

        & > * {
          width: 100%;
          height: 100%;
        }
      }
    }

    .welcome {
      position: relative;
      font-family: $secondary-font;
      width: 100%;
      padding-bottom: 30px;

      .description {
        margin: 0;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

  .not-found {
    padding-block: 20px;
    gap: 72px;

    .form {
      width: min(520px, calc(100% - 20px));
    }

    .content {
      gap: 8px;
    }

    .text {
      font-style: italic;
      font-weight: 300;
      color: $gray-dark;
    }
  }

  :deep(.col-primary) {
    color: $primary;
  }
}

