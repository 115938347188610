
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.issues-summary-header {
  background-color: $gray-light-4;
  grid-template-columns: var(--issues-summary-template-columns);
  font-size: 14px;

  .cell {
    padding: 6px 8px;

    p {
      font-family: $secondary-font;
      font-weight: 400;
    }
  }

  .icon {
    width: 20px;
    aspect-ratio: 1;
    color: $gray-2;
  }

  p {
    margin: 0;
  }
}

