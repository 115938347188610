
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.requester-contact {
  padding: 16px;
  border: 1px solid $gray-light;
  border-radius: 8px;

  .name {
    font-weight: 500;
    font-family: $secondary-font;
    line-height: 1.5;
  }

  p {
    margin-block: 0;
  }

  .detail {
    font-size: 14px;
  }

  .reset-button {
    appearance: none;
    background-color: transparent;
    border-radius: 50%;
    border: none;
    width: 24px;
    aspect-ratio: 1;
    padding: 0;
    cursor: pointer;

    &:hover:not(:disabled, [disabled]) {
      background-color: $gray-light-3;

      &:active {
        background-color: $gray-light-2;
      }
    }
  }

  .edit {
    color: $orange;

    &:disabled,
    &[disabled] {
      color: $gray-light;
      cursor: not-allowed;
    }
  }

  .fold {
    transition: transform 0.3s ease;

    &.retracted {
      transform: rotate(-0.5turn);
    }
  }
}

