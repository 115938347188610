
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.messages-history {
  padding-inline: 20px;
}

