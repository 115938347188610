
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.tickets-reschedule-supplier {
  grid-template-columns: 1fr 330px;
  gap: 57px;

  .change-supplier {
    height: 24px;
    justify-self: flex-start;
  }

  .content-start {
    align-content: flex-start;
  }

  .border-wrapper {
    border: 1px solid #EAEAEA;
    border-radius: 8px;
    padding: 24px;
  }

  .divider {
    min-height: 1px;
    min-width: 1px;
    background-color: $gray-light-2;
  }
}

