
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.vehicle-manufacturer-icon {
  width: var(--size);
  height: var(--size);
  text-align: left;
}

