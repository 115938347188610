// Colors
$aqua-darker: #1EAFB8;
$aqua-light:  #D9F8FF;

$blue-dark:      #0077DC;
$blue:           #3FA2F7;
$blue-light:     #C4E4FF;
$blue-light-2:   #D9EEFF;

$green-darker:  #1F5228;
$green-dark:    #3BB14E;
$green:         #56C568;
$green-light:   #CAF7D1;
$green-light-2: #F1FFEB;

$red-dark:     #D33F3F;
$red:          #EB5757;
$red-light:    #F7CDCD;
$red-light-2:  #FFEBEB;

$yellow-dark:    #FFA800;
$yellow:         #FFC400;
$yellow-light:   #FDEEBF;
$yellow-light-2: #FFF7DB;

$orange-dark:    #DD4A26;
$orange-2:       #EE6A28;
$orange:         #F4781F;
$orange-light-0: #FFCFA3;
$orange-light:   #FFD0A3;
$orange-light-2: #FFEFE0;
$orange-light-3: #FFF5EC;

$purple-dark:    #43144A;
$purple:         #622279;
$purple-2:       #C262E4;
$purple-light:   #C291D3;
$purple-light-2: #F2E5F6;

$black:        #000000;
$gray-dark:    #333333;
$gray-dark-2:  #525252;
$gray:         #666666;
$gray-2:       #959595;
$gray-3:       #C4C4C4;
$gray-light:   #D4D4D4;
$gray-light-2: #EAEAEA;
$gray-light-3: #F6F6F6;
$gray-light-4: #FAFAFA;
$gray-light-5: #FCFCFC;
$white:        #FFFFFF;

$bg-hover:     #FFF8F1;
$bg-active:    #FAF0E6;

$primary-dark:    $orange-dark;
$primary-2:       $orange-2;
$primary:         $orange;
$primary-light:   $orange-light;
$primary-light-2: $orange-light-2;
$primary-light-3: $orange-light-3;

$info-dark:     $blue-dark;
$info:          $blue;
$info-light:    $blue-light;
$info-light-2:    $blue-light-2;

$success-darker:  $green-darker;
$success-dark:    $green-dark;
$success:         $green;
$success-light:   $green-light;
$success-light-2: $green-light-2;

$error-dark:    $red-dark;
$error:         $red;
$error-light:   $red-light;

$warning-dark:  $yellow-dark;
$warning:       $yellow;
$warning-light: $yellow-light;

$text:          $gray-dark;
$background:    $gray-light-3;
$placeholder:   $gray-light;

// Text
$primary-font:   "Open Sans", Helvetica, Arial, sans-serif;
$secondary-font: "Rubik", Helvetica, Arial, sans-serif;
$primary-monospace-font: "Roboto Mono", "Lucida Console", Monaco, monospace;

// Layout variables
$sidebar-width: 248px;
$navbar-height: 55px;
$outdated-height: 40px;
