
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.expended-padding {
  padding: 2px 8px;;
}

