
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.issues-summary-row {
  grid-template-columns: var(--issues-summary-template-columns);

  .cell {
    padding: 12px 8px;
  }

  .icon {
    width: 20px;
    aspect-ratio: 1;
    color: $gray-3;
  }

  .name {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
  }

  .description {
    font-size: 12px;
  }

  .part,
  .target {
    gap: 6px;
    font-family: $primary-font;
  }

  .part {
    font-size: 14px;
  }

  .targets {
    font-size: 12px;
    margin-left: 2.5px;
    padding-left: 8.5px;
    border-left: 1px solid $gray-3;
  }

  .gray {
    color: $gray-3;
  }

  .red {
    color: #ff0000;
  }

  .purchase-intent-container {
    display: flex;
    align-items: center;
  }
  .purchase-intention-icon {
    margin-right: 4px;
    font-size: 10px;
  }

  .fa-circle {
    font-size: 6px;
  }

  .fa-diamond {
    font-size: 4px;
  }

  p {
    margin: 0;
  }

  .empty {
    font-family: $secondary-font;
    font-size: 16px;
    font-weight: 400;
  }
}

