
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.modal-body {
  padding: 24px;
}

.app-modal {
  $modal-z-index: 400 !default;
  position: fixed;
  z-index: $modal-z-index;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($gray-dark, 0.7);

  .modal-wrapper {
    margin: 0 auto;
    z-index: $modal-z-index + 1;
  }

  .modal-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 94%;
    // Altura total menos margin
    max-height: calc(100vh - 40px);
    max-width: calc(100vw - 40px);
    margin: 20px auto;
    margin-top: 40px;
    overflow: auto;
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba($black, 0.33);

    &.full-height {
      height: 100%;
    }

    &.full-height,
    &.view {
      height: calc(100vh - 80px);
    }

    &.view {
      max-width: 1200px;
    }
  }

  .actions {
    position: absolute;
    z-index: $modal-z-index + 2;
    right: 24px;
    top: 16px;

    .action,
    :deep(.action) {
      border: 0;
      outline: 0;
      padding: 0;
      margin: 0;
      width: 24px;
      height: 24px;
      font-size: 16px;
      color: $gray-dark;
      background-color: transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      & + .action {
        margin-left: 16px;
      }

      &:hover {
        color: $black;
      }
    }
  }

  .modal-header {
    color: $orange;
    margin-top: 0;
    padding: 16px 24px;
    border-bottom: 1px solid $gray-light;
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    padding: 16px 24px;

    &.with-border {
      border-top: 1px solid $gray-light;
    }
  }

  .modal-heading {
    font-size: 18px;
    font-weight: 400;
    color: $gray-dark;
    font-family: $secondary-font;
  }

  .modal-photo {
    padding: 16px;

    .action {
      background-color: $white;
      margin: 8px;
      border-radius: 50%;
    }
  }

  .modal-content {
    overflow: auto;
    flex-grow: 1;
  }
}

