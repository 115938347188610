
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.type-grid {
  grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
  grid-auto-rows: minmax(200px, auto);
}

