
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.tickets-table-row-state-icon {
  $icon-colors: (
    'green': $green,
    'green-darker': $green-darker,
    'gray-light':$gray-light,
    'orange': $orange,
    'red': $red,
    'yellow': $yellow,
  );

  @each $name, $color in $icon-colors {
    .text-#{$name} {
      color: $color;
    }
  }
}

