
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.supplier-appointment-scheduler {
  margin-top: 24px;

  .section-title {
    margin: 0;
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 300;
    color: $gray-dark;
  }

  .button-reset {
    border: none;
    background-color: transparent;
    border-radius: 8px;
    cursor: pointer;
  }

  .arrow {
    padding: 0;
    width: 24px;
  }

  .radio {
    display: none;
  }

  .error {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

