
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.requester-edit{
  .footer {
    border-top: 1px solid $gray-light;
  }
}

.padding-regular {
  padding: 16px
}

.checkbox {
  padding-top: 16px;
  border-top: 1px solid $gray-light-2;

  &-text {
    font-family: $secondary-font;
    font-weight: 400;
    padding-inline: 16px;
  }
}

