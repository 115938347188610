
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.radio-field {
  .field-label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
    color: $gray-dark;
    transition: color 0.1s cubic-bezier(.645,.045,.355,1);

    > * + * {
      padding-left: 4px;
    }

    &.focus {
      color: $orange;
    }

    &.error {
      color: $error;
    }
  }

  .loading-lines {
    width: 20px;
    height: 20px;
  }

  .radio {
    display: flex;
    align-items: center;

    .radio-input {
      flex-shrink: 0;
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $gray-light-4;
      border: 1px solid $gray-3;
      margin: 4px 0;
      transition: all .1s;
      cursor: pointer;

      &:hover,
      &:focus,
      &:active {
        border-color: $orange;
      }

      &:active {
        background-color: $orange-light;
      }

      &.disabled,
      &.loading {
        background-color: $gray-light-4;
        border-color: $gray-3;
      }

      &.disabled {
        cursor: not-allowed;
      }

      &.loading {
        cursor: progress;
      }

      &:checked {
        border-color: $orange;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          //
          display: block;
          width: 12px;
          aspect-ratio: 1;
          background-color: $orange;
          border-radius: 50%;
        }

        &:focus,
        &:active {
          border-color: $orange-dark;
        }

        &:active {
          background-color: $orange-dark;
        }

        &.disabled,
        &.loading {
          background-color: $gray-light-2;
          border-color: $gray-light;

          &:after {
            background-color: $gray-light;
          }
        }
      }
    }
  }

  .radio-label {
    padding-left: 8px;
    margin-right: 8px;
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    user-select: none;

    &.disabled {
      cursor: not-allowed;
    }

    &.loading {
      cursor: progress;
    }

    &.disabled,
    &.loading {
      color: $gray-2;
    }

    &.error {
      color: $error;
    }
  }

  .error-message {
    display: block;
    color: $error;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

