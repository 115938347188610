
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.issues-summary {
  .issues-table {
    --issues-table-border: 1px solid #{$gray-light};
    border: var(--issues-table-border);
    border-radius: 4px;
    overflow: hidden;
  }

  .row {
    border-top: var(--issues-table-border);
  }

  .count {
    font-size: 14px;
  }

  .cart {
    font-family: $secondary-font;
    font-weight: 400;
  }

  .fa-cart-shopping {
    font-size: 12px;
    color: $gray;
  }

  .fa-diamond {
    font-size: 4px;
    color: $gray-3
  }

  .font-small {
    font-size: 14px;
    font-weight: 400;
  }
}

