
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.tickets-table {
  .outside {
    font-size: 14px;

    &-default {
      text-align: end;
      min-height: 19px;

      p {
        margin: 0;
      }
    }
  }

  .counter {
    font-family: $primary-font;
    font-weight: 300;
  }

  .tickets-footer {
    background-color: $gray-light-4;
    padding-block: 8px;
    border-block: 1px solid $gray-light-2;
  }

  .empty {
    background-color: $gray-light-3;
    padding: 12px;
    border-top: 1px solid $gray-light-2;
    border-bottom: 1px solid $gray-light-2;

    p {
      margin: 0;
      color: $gray;
      font-size: 14px;
      font-style: italic;
    }
  }

  .font-small {
    font-size: 14px;
    font-weight: 400;
  }

  .fa-diamond {
    font-size: 4px;
    color: $gray-3;
  }

  .fa-steering-wheel {
    font-size: 12px;
  }

  .icon {
    width: 16px;
    aspect-ratio: 1;
  }

  .dimmed {
    opacity: 0.4;
  }

  &.table-empty :deep(.footer) {
    display: none;
  }
}

