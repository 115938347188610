
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.alerts {
  position: fixed;
  display: flex;
  top: 14px;
  right: 15px;
  z-index: 9999;

  .alert {
    display: flex;
    width: 330px;
    padding: 14px 26px 14px 13px;
    border-radius: 8px;
    border: 1px solid $gray-light-2;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    margin-bottom: 12px;

    .icon {
      font-size: 26px;

      &.success {
        color: $green;
      }
      &.warn {
        color: $yellow;
      }
      &.error {
        color: $red;
      }
    }

    .group {
      margin-left: 13px;
      margin-right: 8px;

      .content {
        font-size: 14px;
        line-height: 21px;
        color: $gray-dark;
      }

      .close-btn {
        position: absolute;
        top: 9px;
        right: 15px;
        cursor: pointer;
        color: $gray-2;
        font-size: 24px;

        &:hover {
          color: $gray-dark;
        }
      }
    }
  }
}

