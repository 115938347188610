
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.channels-selector {
  .option {
    input {
      position: absolute;
      top: -9999vh;
      left: -9999vw;
    }

    &.disabled {
      opacity: 0.6;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        cursor: not-allowed !important;
      }
    }
  }

  .icon {
    width: 16px;
    aspect-ratio: 1;
  }

  p {
    margin: 0;
  }
}

