
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.contract-summary {
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  column-gap: 24px;
}

