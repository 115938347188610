
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.ticket-row {
  --ticket-row-border: 1px solid #{$gray-light-2};

  grid-template-columns: var(--ticket-template-columns);
  gap: var(--ticket-gap);
  border-top: var(--ticket-row-border);
  font-family: $secondary-font;
  font-weight: 400;


  &.darkend {
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      background-color: $gray-light-3;
      inset: 0;
      z-index: -1;
    }

    &>* {
    opacity: 0.5;
    }
  }

  &:last-of-type {
    border-bottom: var(--ticket-row-border);
  }

  p {
    margin: 0;
  }

  .cell {
    padding: 12px;
    font-size: 14px;

    &.clickable{
      &:hover {
        color: $orange;
        cursor: pointer;
      }

      ::selection {
        background-color: transparent;
      }
    }
  }

  .tag-inline-block{
    display: inline-block;
  }

  .text-small {
    font-size: 12px;
  }

  .not-available {
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    color: $gray;
  }

  .fa-diamond {
    font-size: 4px;
    color: $gray-3
  }

  .primary-font {
    font-family: $primary-font;
    color: $gray;
  }

  .gap-smaller {
    column-gap: 4px;
  }

  .text-max {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-break: anywhere;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .font-size-regular {
    font-size: 1rem;
  }
}

.popover-button {
  appearance: none;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-family: $primary-font;
  font-weight: 300;
  padding: 4px 8px;
  transition: color 0.1s ease-out;
  cursor: not-allowed;

  &:not(:disabled, [disabled]) {
    cursor: pointer;

    &:hover {
      color: $orange;

      &:active {
        color: $orange-dark;
      }
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid $gray-light-2;
  }
}

