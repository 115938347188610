
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.service {
  gap: 54px;
  grid-template-columns: 1fr 330px;

  .main {
    align-content: start;
  }

  .title {
    font-size: 24px;
  }

  .aside {
    align-content: start;
  }

  .divider {
    min-width: 1px;
    min-height: 1px;
    background-color: $gray-light-2;
  }
}

