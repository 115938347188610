
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.ticket-timeline {
  color: $gray-dark;
  font-size: 14px;

  p {
    margin: 0;
  }

  .skeleton {
    grid-template-columns: 82px 1fr;
    column-gap: 16px;
    row-gap: 24px;
  }

  .title {
    font-family: $secondary-font;
    font-size: 16px;
    font-weight: 500;
  }

  .table {
    --timeline-table-padding-block: 12px;
    --timeline-table-line-gap: 5px;

    grid-template-columns: 82px auto 1fr;
    column-gap: 16px;

    .left {
      .accent {
        width: 16px;

        &.translate {
          transform: translateY(-5px);
        }
      }
    }

    .middle {
      gap: var(--timeline-table-line-gap);
      grid-column-start: 2;
    }

    .right {
      grid-column-start: 3;
    }

    .vertical-line {
      width: 2px;
      background-color: $gray-light-2;

      &.top {
        flex-basis: calc(
          var(--timeline-table-padding-block) - var(--timeline-table-line-gap)
        );
      }
    }
  }

  .reason {
    font-weight: 400;
    font-size: 12px;
  }

  .padding-block {
    padding-block: 12px;
  }

  .fs-tiny {
    font-size: 12px;
  }

  .ff-secondary {
    font-family: $secondary-font;
    font-weight: 400;
  }

  $local-colors: (
    "purple": $purple,
    "gray": $gray,
    "gray-3": $gray-3,
    "green": $green,
    "yellow": $yellow,
    "red": $red,
  );

  @each $name, $color in $local-colors {
    .color-#{$name} {
      color: $color;
    }
  }
}

