
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.vehicle-tickets {
  .summary {
    font-weight: 400;
  }

  .font-small {
    font-size: 14px;
    font-weight: 400;
  }

  .footer {
    background-color: $white;
    padding-block: 8px;
    border-block: 1px solid $gray-light-2;
  }
}

