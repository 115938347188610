
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.supplier-appointment-options-item {
  .name {
    font-family: $secondary-font;
    font-size: 20px;
    font-weight: 400;
  }

  .address {
    font-style: normal;
    color: $gray-dark;
    font-size: 14px;
  }

  .details {
    margin-top: 8px;
    column-gap: 16px;
    row-gap: 8px;
  }

  .detail {
    gap: 4px;
    font-size: 14px;
    color: $gray;
  }

  .icon {
    width: 20px;
    aspect-ratio: 1;
  }

  .golden {
    display: inline-block;
    font-family: $secondary-font;
    font-size: 12px;
    font-weight: 500;
    color: $gray-dark;
    background-color: $yellow-light;
    border: 1px solid $yellow;
    border-radius: 24px;
    padding: 4px 8px;
  }

  .monospace {
    font-family: $primary-monospace-font;
    font-size: 14px;
    font-weight: 400;
  }
}

