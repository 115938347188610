
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.messages-history-create-modal {
  p {
    margin: 0;
  }

  .section {
    padding: 16px 24px;

    &:not(:first-child) {
      border-block-start: 1px solid $gray-light-2;
    }
  }

  .field-header {
    font-size: 14px;
    font-family: $secondary-font;
    font-weight: 400;
  }

  .field-icon {
    color: $gray;
    width: 16px;
    aspect-ratio: 1;
  }

  .checkbox-text {
    font-family: $secondary-font;
    font-weight: 400;
    padding-inline-start: 8px;
  }
}

