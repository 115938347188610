
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.modal-supplier-notifications-create {
  --supplier-notifications-border: 1px solid #{$gray-light-2};

  p {
    margin: 0px;
  }

  .wrapper {
    padding: 16px 24px;
  }

  .footer {
    border-block-start : var(--supplier-notifications-border);
  }
}

