
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.tickets-search {
  --tickets-search-fixed-field-width: 269px;
  --tickets-search-fixed-field-width-small: 160px;

  .field-fixed {
    width: var(--tickets-search-fixed-field-width)
  }

  .field-fixed-small {
    width: var(--tickets-search-fixed-field-width-small)
  }

  .submit {
    appearance: none;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid currentColor;
    color: $orange;
    padding: 0;
    width: 40px;
    aspect-ratio: 1;

    &:not([disabled], :disabled):hover {
      color: $orange-dark;
      cursor: pointer;
    }

    &[disabled],
    &:disabled {
      cursor: not-allowed;
      color: $gray-light;
    }
  }

  .footer {
    min-height: 22px;

    &-button-content {
      font-size: 14px;
      color: $gray-dark;

      &.disabled {
        color: $gray-2
      }

      &:not(.disabled):hover {
        color: $gray-dark-2;
      }
    }
  }
}

