
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.appointment-data {
  background-color: $gray-light-4;
  border: 2px solid transparent;
  padding: 16px;
  border-radius: 8px;
  font-family: $secondary-font;

  p {
    margin: 0;
  }

  &.skeleton {
    border-color: $gray-light-2;
  }

  .skeleton-content {
    grid-template-columns: 32px 1fr;
  }

  .icon {
    width: 32px;
    aspect-ratio: 1;
    font-size: 24px;
  }

  .decoration {
    font-size: 0.25rem;
    line-height: 1;
    color: $gray-3;
  }

  .title {
    font-weight: 500;
  }

  .canceled {
    --appointment-data-spacing: 8px;

    font-size: 14px;
    line-height: 1.5;

    &>:nth-child(2) {
      padding-block-start: var(--appointment-data-spacing);
      margin-block-start: var(--appointment-data-spacing);
      border-block-start: 1px solid $gray-light-2;
      font-weight: 400;
    }

    .reason {
      font-size: 12px;
      margin-block-start: var(--appointment-data-spacing);
    }
  }

  $theme-colors: (
    'gray': ($gray-2, $gray-3),
    'yellow': ($yellow-dark, $yellow-light-2),
    'green': ($green, $green-light),
    'red': ($red, $red-light-2),
  );

  @each $name, $value in $theme-colors {
    .color-#{$name} {
      color: nth($value, 1);
    }

    &.border-color-#{$name}:not(.skeleton) {
      border-color: nth($value, 2);
    }
  }
}

