
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.drivers-table {
  .outside {
    font-size: 14px;
  }

  .summary {
    font-weight: 400;
  }

  .counter {
    font-family: $primary-font;
    font-weight: 300;
  }

  .font-small {
    font-size: 14px;
    font-weight: 400;
  }

  .fa-diamond {
    font-size: 4px;
    color: $gray-3;
  }

  .fa-steering-wheel {
    font-size: 12px;
  }

  .icon {
    width: 16px;
    aspect-ratio: 1;
  }

  .transparent {
    opacity: 0.4;
  }

  .no-margin-bottom :deep(.footer) {
    margin-bottom: 0;
  }

  .empty {
    background-color: $gray-light-3;
    padding: 12px;
    border-top: 1px solid $gray-light-2;
    border-bottom: 1px solid $gray-light-2;

    p {
      margin: 0;
      color: $gray;
      font-size: 14px;
      font-style: italic;
    }
  }
}

