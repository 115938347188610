
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.tag-inline-ticket-state{
  .icon {
    width: 20px;
    aspect-ratio: 1;
  }

  .status {
    color: $gray-dark;
  }
}

