
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.tickets-draft-confirmation.grid {
  grid-template-columns: 1fr 330px;
  gap: 56px;

  p {
    margin: 0;
  }

  .main {
    align-content: flex-start;
  }

  .title {
    font-family: $secondary-font;
    font-size: 24px;
    font-weight: 400;
  }

  .divider {
    min-height: 1px;
    min-width: 1px;
    background-color: $gray-light-2;
  }

  .aside {
    align-content: flex-start;
  }

  .select-channels {
    padding-block-start: 8px;
  }

  .error-message {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }

  .field-header {
    font-size: 14px;
    font-family: $secondary-font;
    font-weight: 400;
  }

  .field-icon {
    color: $gray;
    width: 16px;
    aspect-ratio: 1;
  }

  .checkbox-text {
    font-family: $secondary-font;
    font-weight: 400;
    padding-inline-start: 8px;
  }
}

