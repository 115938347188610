
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.supplier-appointment-options {
  --supplier-border: 1px solid #{$gray-light-2};

  border: var(--supplier-border);
  border-radius: 8px;

  .option {
    padding: 24px;
    grid-template-columns: 24px 1fr;

    &:not(:last-child) {
      border-bottom: var(--supplier-border);
    }
  }
}

