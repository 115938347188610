
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.textarea-field {
  .label-content {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
    color: $gray-dark;
    transition: color 0.1s cubic-bezier(.645,.045,.355,1);

    > * + * {
      padding-left: 4px;
    }

    &.focus {
      color: $orange;
    }

    &.error {
      color: $red;
    }

    .mandatory-icon {
      font-size: 4px;
      color: $orange;
    }

    .info-icon {
      font-size: 14px;
      cursor: pointer;
    }
  }

  .input-wrapper {
    position: relative;
    font-size: 16px;
    display: inline-block;
    width: 100%;

    .input-inner {
      font-family: $primary-font;
      appearance: none;
      background-color: $white;
      background-image: none;
      border-radius: 4px;
      border: 1px solid $gray-3;
      color: $gray;
      display: inline-block;
      font-size: inherit;
      outline: none;
      padding: 8px 13px;
      width: 100%;
      resize: vertical;
      height: initial;
      line-height: initial;
      min-height: 80px;

      &:hover {
        border-color: $orange;
      }

      &:focus {
        outline: none;
        border-color: $orange;
        border-width: 2px;
        padding: 7px 12px;
      }

      &::placeholder {
        font-weight: 300;
        color: $gray-3;
        opacity: 1;
      }

      &.disabled {
        background-color: $gray-light-2;
        border-color: $gray-light;
        color: $gray-2;
        cursor: not-allowed;
      }

      &.loading {
        background-color: $gray-light-2;
        border-color: $gray-light;
        color: $gray;
        cursor: progress;
      }

      &.error {
        border-color: $red;
      }
    }
  }

  .error-message {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

