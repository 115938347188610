
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.service-form {
  .divider {
    min-height: 1px;
    min-width: 1px;

    background-color: $gray-light-2;
  }

  .add-service-button {
    gap: 9px;
  }

  .grid.columns-2 {
    grid-template-columns: 1fr 1fr;

    & > * {
      min-width: 1px;
    }
  }
}

