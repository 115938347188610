
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.ticketHeader {
  grid-template-columns: var(--ticket-template-columns);
  gap: var(--ticket-gap);
  border-radius: 8px 8px 0 0;
  background-color: $gray-light-4;
  font-family: $secondary-font;

  p {
    margin: 0;
  }

  .cell {
    padding: 8px;
    font-size: 0.875rem;
  }

  .gray-2 {
    color: $gray-2
  }

  .fa-diamond {
    font-size: 8px;
    color: $gray-light;
  }

  .clickable {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    &:not(.disabled) {
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);

        &:active {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }

      ::selection {
        background: transparent;
        color: inherit;
      }
    }
  }

  .color-orange {
    color: $orange;
  }
}

