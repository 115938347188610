
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.tickets-show {
  margin-block: 24px;
  grid-template-columns: 1fr 330px;

  p {
    margin: 0;
  }

  .two-columns {
    grid-template-columns: repeat(2, 1fr);
  }

  .divider {
    min-width: 1px;
    min-height: 1px;
    background-color: $gray-light-2;
  }

  .contract {
    padding: 24px;
    border-radius: 8px;
    background-color: $gray-light-4;
  }

  .drivers-outside {
    .icon {
      width: 16px;
      aspect-ratio: 1;
      font-size: 12px;
      color: $gray;
    }

    .drivers-title {
      font-family: $secondary-font;
      font-size: 14px;
      font-weight: 400;
    }

    .drivers-count {
      font-size: 14px;
      font-weight: 300;
    }
  }

  .decoration {
    font-size: 0.25rem;
    line-height: 1;
    color: $gray-3;
  }
}

.content-start {
  align-content: flex-start;
}

