
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.requester {
  --requester-padding: 16px;
  --requester-gap-small: 8px;

  border: 1px solid $gray-light-2;
  border-radius: 8px;
  padding: var(--requester-padding);
  gap: var(--requester-gap-small);

  &.selected {
    border-color: $orange;

    &.disabled {
      border-color: $gray-light;
    }
  }

  &:not(.selected, .disabled):hover {
    background-color: $gray-light-4;
  }

  .grid-template {
    grid-template-columns: var(--requester-grid-template-columns);
  }

  p {
    margin: 0;
  }

  .person {
    small {
      color: $gray;

      i {
        font-size: 10px;
      }

      span {
        font-family: $primary-font;
        font-size: 12px;
      }
    }
  }

  .details,
  .detail {
    gap: var(--requester-gap-small);
  }

  .detail {
    min-width: 0;
  }

  .icon {
    width: 20px;
    aspect-ratio: 1;
    flex-shrink: 0;
  }

  .edit {
    align-self: start;
    justify-self: end;
    width: 24px;
    aspect-ratio: 1;
    font-size: 16px;
    padding: 0;
    color: $orange;
    background-color: transparent;
    border: none;
    border-radius: 0;
    cursor: pointer;

    &:hover {
      color: $orange-2;
    }
  }

  .transparent {
    opacity: 0.3;
  }
}



