
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.auxiliar-buttons {
  .action-contact {
    width: 100%;
    font-size: 14px;

    &.dark {
      color: $gray;

      &.disabled {
        color: $gray-light;
      }

      &:not(.disabled):hover {
        color: $orange;
      }
    }

    i {
      width: 16px;
      font-size: 12px;
    }
  }
}

