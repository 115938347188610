
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.ticket-contract-header {
  .title {
    font-family: $secondary-font;
    font-size: 20px;
    font-weight: 500;
  }

  .id {
    font-size: 20px;
  }

  .icon {
    width: 16px;
    aspect-ratio: 1;
    color: $gray;
  }

  .decoration {
    font-size: 4px;
    color: $gray-3;
  }

  .small-text {
    color: $gray-dark-2;
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 400;
  }

  .due {
    display: inline-block;
    padding: 4px 12px;
    border-radius: 8px;
    background-color: $green;
    //
    font-family: $secondary-font;
    font-weight: 500;
    color: $white;

    &[data-status="expired"] {
      background-color: $gray-2;
    }
  }

  p {
    margin: 0;
  }
}

