
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.tickets-reschedule-confirmation {
  grid-template-columns: 1fr 330px;
  gap: 57px;

  .title {
    font-family: $secondary-font;
    font-size: 24px;
    font-weight: 400;
  }

  .content-start {
    align-content: flex-start;
  }

  .select-channels {
    padding-block-start: 8px;
  }

  .field-header {
    font-size: 14px;
    font-family: $secondary-font;
    font-weight: 400;
  }

  .field-icon {
    color: $gray;
    width: 16px;
    aspect-ratio: 1;
  }

  .checkbox-text {
    font-family: $secondary-font;
    font-weight: 400;
    padding-inline-start: 8px;
  }
}

