
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.data-box {
  --data-box-spacing-small: 8px;
  --data-box-font-size-small: 0.875em;
  --data-box-border: 1px solid #{$gray-light-2};

  background-color: $white;
  font-family: $secondary-font;
  border: var(--data-box-border);
  border-radius: 8px;
  padding: 16px;
  align-self: flex-start;

  p {
    margin: 0;
  }

  .header {
    font-family: $secondary-font;

    &.with-details {
      padding-bottom: 16px;
      border-bottom: var(--data-box-border);
    }

    .category {
      font-size: var(--data-box-font-size-small);

      i {
        color: $gray;
        width: 16px;
        aspect-ratio: 1;
      }
    }

    h3 {
      font-size: 1.25rem;
      font-weight: 500;
    }

    .title {
      padding-inline-end: 6px;
    }
  }

  .details {
    font-style: normal;

    &.two-columns {
      grid-template-columns: 1fr 1fr;
    }
  }

  .icon {
    width: 20px;
    aspect-ratio: 1;
    font-size: var(--data-box-font-size-small);
    line-height: 1.4;
    color: $gray-dark;
    flex-shrink: 0;
    align-self: first baseline;
  }

  .footer {
    font-style: italic;
    font-size: 0.875rem;
    color: $gray;
  }

  .info-missing {
    font-family: $secondary-font;
    font-size: 18px;
    font-weight: 400;
  }

  .fas.fa-diamond {
    font-size: 0.25rem;
    line-height: 1;
    color: $gray-3;
  }

  .small-text-bold {
    font-size: .75rem;
    font-weight: 600;
  }

  &.skeleton {
    &>:nth-child(1) {
      gap: 10px;
    }
  }
}

