
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.outdated-indicator {
  height: $outdated-height;
  background-color: $orange;
  color: $white;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $gray-3;
  cursor: pointer;
  position: relative;
  z-index: 10000;
}

