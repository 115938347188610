
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.driver-new {
  .footer {
    border-top: 1px solid $gray-light;
  }
}

.padding-regular {
  padding: 16px
}

.go-back {
  color: $gray-dark !important;

  &:hover {
    color: $orange !important;

    &:active {
      color: $orange-dark !important;
    }
  }
}

