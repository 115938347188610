
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.transition-fade-slide {
  &-right,
  &-left {
    &-enter-active,
    &-leave-active {
      transition: all var(--transition-duration) ease-out;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
    }
  }

  &-right-enter-from,
  &-left-leave-to {
    transform: translateX(calc(0px - var(--transition-translation)));
  }

  &-left-enter-from,
  &-right-leave-to {
    transform: translateX(var(--transition-translation));
  }
}

