
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.modal-body {
  padding: 16px 24px;
}

