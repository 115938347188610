/**
 * -----------------
 *  Vue transitions
 * -----------------
 */

/**
 * slide-up transition
 */
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all .3s ease;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
}

.slide-up-fading-enter-active,
.slide-up-fading-leave-active {
  transition: all .3s ease;
}

.slide-up-fading-enter-from,
.slide-up-fading-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

/**
 * slide-down transition
 */
.slide-down-enter-active,
.slide-down-leave-active {
  transition: all .3s ease;
}

.slide-down-enter-from,
.slide-down-leave-to {
  transform: translateY(-100%);
}

.slide-down-fading-enter-active,
.slide-down-fading-leave-active {
  transition: all .3s ease;
}

.slide-down-fading-enter-from,
.slide-down-fading-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}

/**
 * slide-left transition
 */
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all .3s ease;
}

.slide-left-enter-from,
.slide-left-leave-to {
  transform: translateX(100%);
}

.slide-left-fading-enter-active,
.slide-left-fading-leave-active {
  transition: all .3s ease;
}

.slide-left-fading-enter-from,
.slide-left-fading-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

/**
 * slide-right transition
 */
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all .3s ease;
}

.slide-right-enter-from,
.slide-right-leave-to {
  transform: translateX(-100%);
}

.slide-right-fading-enter-active,
.slide-right-fading-leave-active {
  transition: all .3s ease;
}

.slide-right-fading-enter-from,
.slide-right-fading-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

/**
 * fade transition
 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0
}

/**
 * modal transition
 */
.modal-enter-from,
.modal-leave-to {
  opacity: 0;

  .modal-container {
    transform: scale(1.03);
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity .3s;

  .modal-container {
    transition: transform .3s;
  }
}

/**
 * outdated indicator transition
 */
.outdated-down-enter-active {
  animation: outdated-down-height .5s;
}

.outdated-down-leave-active {
  animation: outdated-down-height .5s reverse;
}

@keyframes outdated-down-height {
  0% {
    height: 0;
  }

  100% {
    height: $outdated-height;
  }
}
