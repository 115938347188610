
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.id-tag {
  font-family: $primary-monospace-font;
  display: inline-block;
  color: $purple;
  font-weight: 400;
}

