
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.modal-requester-notifications-contact{
  background-color: $gray-light-4;
  border-radius: 8px;
  padding: 8px;

  .decoration {
    font-size: 0.25rem;
    line-height: 1;
    color: $gray-3;
  }

  .name {
    font-family: $secondary-font;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .qualification {
    font-size: 12px;
    font-weight: 400;
  }

  .contactedBy {
    font-size: 14px;
  }

  .tag {
    font-size: 12px;

    i {
      font-size: 16px;
      color: $gray;
    }
  }
}

