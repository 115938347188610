
          @import "@/assets/styles/variables.scss";
          @import '@/assets/styles/flatpickr.scss';
        


.modal-tickets-table {
  .summary {
    padding: 16px 24px;
    border-bottom: 1px solid $gray-light-2;
    gap: 48px;
  }

  .table {
    padding: 16px 24px 24px;
  }

  .supplier-input {
    min-width: 290px;
  }

  .submit {
    appearance: none;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid currentColor;
    color: $orange;
    padding: 0;
    width: 40px;
    aspect-ratio: 1;

    &:not([disabled], :disabled):hover {
      color: $orange-dark;
      cursor: pointer;
    }

    &[disabled],
    &:disabled {
      cursor: not-allowed;
      color: $gray-light;
    }
  }
}

